<template>
  <div>
<!--    <router-view></router-view>-->
    <div class="page-container">
        <div class="section-top">
            <div class="title-holder">
              <span v-for="items in categories" :key="items.id">
                <a v-if="items.id === id"
                   v-bind:href="'#/Catalog/' + items.parent" class="link-back">
                  <i class="icon icon-arrow-back"></i>
                </a>
              </span>
              <a v-if="id === '0'" v-bind:href="'#/'" class="link-back">
                <i class="icon icon-arrow-back"></i>
              </a>
              <h1 class="title">
                <span class="title-item">
                  <i v-if="id === '0'" class="icon icon-tractor"></i>
                  <span v-for="cat in categories" :key="cat.id">
                    <span v-if="cat.id === id">{{cat.name}}</span>
                  </span>
                  <span v-if="this.id === '0'">{{$ml.with('VueJS').get('catalogTitleLine')}}</span>
                </span>
              </h1>
            </div>
        </div>
        <div class="section-main catalog-main-section">
            <CatalogStart v-if="this.id === '0'" />
            <nav class="catalog-list" v-else>
              {{removeCategory}}
                <a v-for="items in this.arsNew" :key="items.id"
                   v-bind:href="'#/'+ clickSub(items.id) +'/'+items.id">
                    <span class="catalog-image">
                        <img v-bind:src="items.icon" v-bind:alt="items.name"/>
                    </span>
                  <span class="catalog-item-name">{{items.name}}</span>
                </a>
            </nav>
        </div>
    </div>
  </div>
</template>

<script>
module.exports = {
  data () {
    return {
      categories: Array,
      loading: true,
      errored: false,
      id: this.$route.params['id'],
      arsNew: Array,
      savedThis: ''
    }
  },
  watch: {
    $route (to, from) {
      this.id = to.params['id']
    }
  },
  computed: {
    removeCategory: function () {
      this.arsNew = this.categories.filter(cat => {
        console.log(cat)
        if (cat.parent === this.id) {
          this.arsNew = cat
          return this.arsNew
        }
      })
    }
  },
  methods: {
    async loadJsonCategories () {
      if (localStorage.getItem('activeItem') === '1') {
        await this.$http.get('https://nfm.com.ua/index.php?route=api/app/categories/ua/' + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
          .then(response => response.json())
          .then(response => {
            this.categories = response
          })
      } else {
        await this.$http.get('https://nfm.com.ua/index.php?route=api/app/categories/' + '&token=' + localStorage.userToken + '&id=' + localStorage.userId)
          .then(response => response.json())
          .then(response => {
            this.categories = response
          })
      }
      this.Loading = false
    },
    clickSub (idss) {
      var CatalogSection = 'CatalogSection'
      for (var categ of this.categories) {
        if (idss === categ.parent) {
          CatalogSection = 'Catalog'
        }
      }
      return CatalogSection
    }
  },
  mounted () {
    this.loadJsonCategories()
  },
  created () {
    this.categoriesJson = this.$resource('categories/')
  }
}
</script>

<style>
.catalog-list .catalog-item{
  width: 50%;
  height: 50vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.catalog-list .catalog-item:nth-child(2n) {
  border-left: 2px solid #f0f0f0;
}
.catalog-list .catalog-item:nth-child(n+3) {
  border-top: 2px solid #f0f0f0;
}
</style>
